import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import './post.scss';
import Layout from '../components/Layout';
import Header from '../components/Header';
import PostLink from '../components/PostLink';
import Link from '../components/Link';

export default function Post({data: {mdx, prev, next}}) {
  const {
    title,
    description,
    keywords,
    slug,
    author
  } = mdx.frontmatter

  const prevPost = prev ? (
    <PostLink post={prev} context={'previous'} />
  ) : (
    ''
  );
  const nextPost = next ? (
    <PostLink post={next} context={'next'} />
  ) : (
    ''
  );
  let banner, previewImage;
  if (mdx.frontmatter.banner) {
    banner = (
      <Img
        className="banner"
        sizes={mdx.frontmatter.banner.childImageSharp.sizes}
        alt={keywords.join(', ')}
      />
    );
    previewImage = mdx.frontmatter.banner.childImageSharp.sizes.src;
  }
  return (
    <Layout title={title} description={description} keywords={keywords} previewImage={previewImage} path={`/blog/${slug}`}>
      <Header title={mdx.frontmatter.title} sub={mdx.frontmatter.date}/>

      <div className="post">
        <div className="content-container">
          <div className="content">
            {banner}
            <div className="text-content">
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </div>
            <div className="author">
              <Img className="author-image" fluid={author.image.childImageSharp.fluid} alt={author.name}/>
              <div className="author-info">
                <p className="author-name">{author.name}</p>
                <p className="author-bio">{author.bio}</p>
                <div className="author-socials">
                  <Link to={`https://twitter.com/${author.twitter}`}>
                    <i className="icon-twitter" />
                  </Link>
                  <Link to={`https://www.instagram.com/${author.instagram}`}>
                    <i className="icon-instagram" />
                  </Link>
                  <Link to={`${author.website}`}>
                    <i className="icon-link" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="other-posts content-padding">
          <div className="content-container">
            <span className="thin-heading">
              Other articles you might like:
            </span>
            <div className="grid">
              <div>{prevPost}</div>
              <div>{nextPost}</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!, $prev: String, $next: String) {
    mdx(fields: { id: { eq: $id } }) {
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        banner {
          childImageSharp {
            sizes(maxWidth: 964, quality: 100) {
              ...GatsbyImageSharpSizes_withWebp
            }
          }
        }
        slug
        categories
        keywords
        author {
          id
          name
          bio
          image {
            childImageSharp {
              fluid(maxWidth: 120, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          twitter
          instagram
          website
        }
      }
      body
    }
    prev: mdx(fields: { id: { eq: $prev } }) {
      excerpt(pruneLength: 140)
      id
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        banner {
          childImageSharp {
            sizes(maxWidth: 720) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        slug
        categories
        keywords
      }
    }
    next: mdx(fields: { id: { eq: $next } }) {
      excerpt(pruneLength: 140)
      id
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        banner {
          childImageSharp {
            sizes(maxHeight: 300, maxWidth: 720, cropFocus: NORTH) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        slug
        categories
        keywords
      }
    }
  }
`;
